import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import one from "./renders/1.jpeg";
import two from "./renders/1.jpeg";
import three from "./renders/1.jpeg";
import four from "./renders/1.jpeg";
import five from "./renders/1.jpeg";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentCut from "@mui/icons-material/ContentCut";
import NavPc from "./navPc";
import Jakub from "./renders/Jakub.webp";
import Jan from "./renders/Jan.webp";
import Maciej from "./renders/Daria.webp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {Helmet} from "react-helmet";

const text = {
  pic: <CardMedia
  component="img"
  alt="no img"
  width="100%"
  image={Maciej}
  sx={{borderRadius:5,
  }}
/>,
 title: " Daria Meysztowicz",
 parg1: "Radca prawny. ",
 parg2: "Specjalizuje się w kompleksowym wsparciu podmiotów w różnego rodzaju transakcjach inwestycyjnych, w szczególności inwestycjach typu venture capital,  a także w doradztwie Klientom w zakresie ich bieżącej działalności gospodarczej.",
 parg3: "Posiada duże doświadczenie w negocjowaniu umów inwestycyjnych oraz nadzorowaniu procesów due diligence. Uczestniczyła w wielu transakcjach z udziałem międzynarodowych funduszy inwestycyjnych oraz prywatnych inwestorów (angel investors) na różnych etapach finansowania (od Pre-seed do Series A). Pozwala jej to udzielać Klientom praktycznych porad i doradzać w zakresie strategicznych rozwiązań, aby osiągnąć ich cele w ramach danej transakcji.",
 parg4: "Przy doradztwie Klientom w zakresie ich bieżącej działalności gospodarczej zawsze stara się patrzyć na całokształt działań, aby sugerowane rozwiązania i wprowadzone procesy jak najlepiej usprawniały bieżącą aktywność Klienta.",
 parg5: "Absolwentka prawa i filologii romańskiej na Uniwersytecie Jagiellońskim w Krakowie. Biegle posługuje się językiem angielskim, francuskim oraz hiszpańskim.",
 buttonText: "+48690720320",
 buttonVariant: "outlined",
 buttonText2: "d.meysztowicz@kmtlegal.pl",
 buttonVariant2: "outlined",
 buttonText3: "LinkedIn",
 buttonVariant3: "outlined",

};
// zmienic na czarny otline przyciskow
function PricingContent() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavPc/>
      <Grid container spacing={0} alignItems="">
      <Grid item key="about" xs={0} md={0} lg={3}>
          <Card
            data-aos="fade-right"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "101vh",
              alignItems: "justify",
            }}
          >
         
           
          </Card>
        </Grid>
        <Grid item key="about" xs={12} md={3} lg={2}>
          <Card
            data-aos="fade-right"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "101vh",
              alignItems: "justify",
              py: 5,
            }}
          >
           
           {text.pic}
            <CardContent>
            <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "baseline",
                  mb: 2,
                }}
              >
                <Typography
                  component="h4"
                  variant="h4"
                  align="center"
                  color="text.third"
                  gutterBottom
                > 
                  {text.title}
                </Typography>
                <br />
               <Button href="tel:+48690720320" fullWidth color="third" variant={text.buttonVariant}>
                  {text.buttonText}
                </Button>
                <br /><br />
                <Button href="mailto:d.meysztowicz@kmtlegal.pl" fullWidth color="third"  variant={text.buttonVariant2}>
                  {text.buttonText2}
                </Button>
                <br /><br />
                <Button href="https://www.linkedin.com/in/daria-meysztowicz-a15215173/?originalSubdomain=pl" fullWidth color="third"  variant={text.buttonVariant2}>
               {text.buttonText3} <LinkedInIcon />
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item key="bio" xs={12} md={9} lg={7}>
          <Card
            data-aos="fade-justify"
            sx={{
              minHeight: "101vh",

              py: 5,
            }}
          >
            <CardContent>
              <Box
                sx={{
                  justifyContent: "justify",
                  maxWidth: "sm",
                  mb: 2,
                  
                }}
              >
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg1}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg2}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg3}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg5}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg6}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg7}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Helmet>
        
        <title>
        Daria Meysztowicz 
        </title>
        <meta name="description" content="Kancelaria KMT Kozubek Matusiak  obsuga prawna przedsiębiorców, podmiotów gospodarczych i indywidualnych klientów"
        />
        <link rel="canonical" href="https://kmtlegal.pl/Daria%20Meysztowicz"/>
      </Helmet>
      </Grid>
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}
