import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import "aos/dist/aos.css";
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';

import RestoreIcon from "@mui/icons-material/Restore";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BottomNavigation from "@mui/material/BottomNavigation";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import InfoIcon from "@mui/icons-material/Info";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import logo from "./svg/kmt-z-zielony-czerwony.svg";

export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState(0);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      {/* Hero unit */}
      <main>
        <div>
          <Box sx={{backgroundColor:"", display: {
            xs:'flex', sm: 'flex', md: 'none' }, justifyContent:'space-between' }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              
              <Button
                    href="/"
                    
                    variant=""
                    sx={{ 
                      align:"glex-end",
                      my: 1,
                      mr:1,
                      color: "secondary.main", display: "block" }}
                  >
                   <img src={logo} width="120" />
                  </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
             
            <MenuItem key={1} onClick={handleCloseNavMenu } href="/ONas">
                <Button
                  sx={{ m: 0.2,  }}
                  style={{justifyContent: "flex-start"}}
                  startIcon={<InfoIcon />}
                  onClick={handleCloseNavMenu}
                  color="secondary"
                  fullWidth
                  href="/About"
                >
                  O Nas
                </Button>
                </MenuItem>
                <MenuItem key={11} onClick={handleCloseNavMenu } href="/ONas">
                <Button
                  sx={{ m: 0.2,  }}
                  style={{justifyContent: "flex-start"}}
                  startIcon={<FormatColorTextIcon />}
                  onClick={handleCloseNavMenu}
                  color="secondary"
                  fullWidth
                  href="/Aktualnosci"
                >
                  Aktualności
                </Button>
                </MenuItem>    
                <MenuItem key={2} onClick={handleCloseNavMenu}>
                <Button
                fullWidth
                  sx={{ m: 0.2 }}
                  style={{justifyContent: "flex-start"}}
                  startIcon={<PeopleAltIcon />}
                  color="secondary"
                  onClick={handleCloseNavMenu}
                  href="/KMT"
                >
                  Zespół
                </Button>
                </MenuItem>  <MenuItem key={3} onClick={handleCloseNavMenu}>
                <Button
                fullWidth
                  sx={{ m: 0.2 }}
                  style={{justifyContent: "flex-start"}}
                  startIcon={<BeenhereIcon />}
                  color="secondary"
                  onClick={handleCloseNavMenu}
                  href="/Specjalizacje"
                >
                  Specjalizacje
                </Button>
                </MenuItem>  
                <MenuItem key={4} onClick={handleCloseNavMenu}>
                <Button
                fullWidth
                  sx={{ m: 0.2 }}
                  style={{justifyContent: "flex-start"}}
                  startIcon={<ContactPhoneIcon />}
                  color="secondary"
                  onClick={handleCloseNavMenu}
                  href="/Kontakt"
                >
                  Kontakt
                </Button>
                </MenuItem>  
                <Button
                    sx={{ m: 0.2, mt: 2 }}
                    
                    size="large"
                    // color="primary"
                    href={(window.location.pathname+'En')}
                    onClick ={ localStorage.setItem('lang', 'eng')}
                    
                  >
                    <Typography
                      data-aos="fade-right"
                      component="h6"
                      variant="h6"
                      align="center"
                      color="secondary"
                      sx={{ fontWeight: 900 }}
                    >
                      En
                    </Typography>
                  </Button>
                <MenuItem key={6} onClick={handleCloseNavMenu}>
                <Button
                fullWidth
                  sx={{ m: 0.2 }}
                  style={{justifyContent: "flex-start"}}
                  startIcon={<LinkedInIcon />}
                  color="secondary"
                  onClick={handleCloseNavMenu}
                  href="https://www.linkedin.com/company/kmt-kozubek-matusiak-truszkiewicz/about/"
                  target="_blank"
                >
                 LinkedIn
                  
                </Button>
              
                  </MenuItem>
        
              </Menu>
            </Box>
          
        <Container maxWidth="l">
          <Grid sx={{ py: 0, }} container spacing={1}>
            <Grid item xs={1}>
              <Button
                sx={{
                  m: 0.2,
                  pt: 1.5,
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                }}
                // startIcon={<InfoIcon />}
                size="large"
                color="secondary"
                variant=""
                href="/"
              >
                <img src={logo} width="120" />
              </Button>
            </Grid>

            <Grid items xs={11}>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                }}
                justifyContent="flex-end"
                alignItems="right"
              >
                <Button
                  sx={{ mx: 0.2, mt: 2 }}
                  // startIcon={<InfoIcon />}
                  size="large"
                  color="secondary"
                  variant=""
                  href="/About"
                >
                  <Typography
                    component="h6"
                    variant="h6"
                    align="center"
                    color=""
                    sx={{fontWeight:900 }}
                  >
                    O Nas
                  </Typography>
                </Button>
                <Button
                  sx={{ mx: 0.2, mt: 2 }}
                  // startIcon={<InfoIcon />}
                  size="large"
                  color="secondary"
                  variant=""
                  href="/Aktualnosci"
                >
                  <Typography
                    component="h6"
                    variant="h6"
                    align="center"
                    color=""
                    sx={{fontWeight:900 }}
                  >
                    Aktualności
                    
                  </Typography>
                </Button>
                <Button
                  sx={{ mx: 0.2, mt: 2 }}
                  // startIcon={<InfoIcon />}
                  size="large"
                  color="secondary"
                  variant=""
                  href="/KMT"
                >
                  <Typography
                    component="h6"
                    variant="h6"
                    align="center"
                    color=""
                    sx={{fontWeight:900 }}
                  >
                    Zespół
                  </Typography>
                </Button>
                <Button
                  sx={{ mx: 0.2, mt: 2 }}
                  // startIcon={<InfoIcon />}
                  size="large"
                  color="secondary"
                  variant=""
                  href="/Specjalizacje"
                >
                  <Typography
                    component="h6"
                    variant="h6"
                    align="center"
                    color=""
                    sx={{fontWeight:900 }}
                  >
                    Specjalizacje
                  </Typography>
                </Button>
                <Button
                  sx={{ mx: 0.2, mt: 2 }}
                  // startIcon={<InfoIcon />}
                  size="large"
                  color="secondary"
                  variant=""
                  href="/Kontakt"
                >
                  <Typography
                    component="h6"
                    variant="h6"
                    align="center"
                    color=""
                    sx={{fontWeight:900 }}
                  >
                    Kontakt
                  </Typography>
                </Button>

                <Button
                    sx={{ m: 0.2, mt: 2 }}
                    
                    size="large"
                    // color="primary"
                    href={(window.location.pathname+'En')}
                    onClick ={ localStorage.setItem('lang', 'eng')}
                    
                  >
                    <Typography
                      data-aos="fade-right"
                      component="h6"
                      variant="h6"
                      align="center"
                      color="secondary"
                      sx={{ fontWeight: 900 }}
                    >
                      En
                    </Typography>
                  </Button>
                <Button
                  sx={{ m: 0.2, mt: 3 }}
                  size="large"
                  color="secondary"
                  variant=""
                  href="https://www.linkedin.com/company/kmt-kozubek-matusiak-truszkiewicz/about/"
                  target="_blank"
                >
                  <Typography
                    component="h6"
                    variant="h6"
                    align="center"
                    color=""
                    sx={{fontWeight:900 }}
                  >
                    <LinkedInIcon />
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
     </div> </main>
    </React.Fragment>
  );
}
