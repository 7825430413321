import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {Helmet} from "react-helmet";

import one from "./renders/1.jpeg";
import two from "./renders/1.jpeg";
import three from "./renders/1.jpeg";
import four from "./renders/1.jpeg";
import five from "./renders/1.jpeg";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentCut from "@mui/icons-material/ContentCut";
import NavPc from "./navPc";
import Jakub from "./renders/Jakub.webp";
import Jan from "./renders/Jan.webp";
import Maciej from "./renders/Maciej.webp";
const text = {
  pic: <CardMedia
  component="img"
  alt="no img"
  width="100%"
  image={Jakub}
  sx={{borderRadius:5,
  }}
/>, title: " Jakub Kozubek",
  parg1: "Radca prawny i partner zarządzający KMT.  ",
  parg2: "Specjalizuje się w prawie cywilnym i gospodarczym (korporacyjnym).",
  parg3: "Swoje wieloletnie doświadczenie zdobywał w renomowanych krakowskich kancelariach, będąc częścią zespołów specjalizujących się w prawie gospodarczym i w postępowaniach sądowych. W ramach swojej dotychczasowej praktyki skupiał się przede wszystkim na prowadzeniu bieżącej obsługi przedsiębiorców, w tym największych deweloperów i administratorów nieruchomości w Polsce oraz zagranicą, czy przedsiębiorców z branży FMCG. Ponadto, posiada bogate doświadczenie w reprezentowaniu podmiotów w postępowaniach sądowych, w tym w postępowaniach odszkodowawczych związanych z inwestycjami budowlanymi, postępowaniach o ogłoszenie upadłości i po jej ogłoszeniu. Był również częścią międzynarodowego zespołu prawników zajmującego się m.in. problematyką ochrony interesów osób poszkodowanych w zamachach terrorystycznych.",
  parg4: "Jest autorem wielu analiz prawnych m.in. w przedmiocie wyboru odpowiedniej struktury transakcji pozwalającej pozyskać finansowanie i skutecznie przeprowadzić akwizycję spółki z branży FMCG, jak również w przedmiocie określenia optymalnego sposobu zabezpieczenia potencjalnych roszczeń podmiotów zagranicznych z branży produkcyjnej.",
  parg5: "Absolwent Wydziału Prawa i Administracji Uniwersytetu Jagiellońskiego w Krakowie. Członek Okręgowej Izby Radców Prawnych w Krakowie.",
  parg6: "Posługuje się językiem angielskim, w tym terminologią prawniczą.",
  parg7:"Prywatnie entuzjasta rynków kapitałowych oraz motoryzacji i narciarstwa alpejskiego.",
  buttonText: "+48690720320",
  buttonVariant: "outlined",
  buttonText2: "j.kozubek@kmtlegal.pl",
  buttonVariant2: "outlined",
  buttonText3: "LinkedIn",
  buttonVariant3: "outlined",

};
// zmienic na czarny otline przyciskow
function PricingContent() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavPc/>
      <Grid container spacing={0} alignItems="">
      <Grid item key="about" xs={0} md={0} lg={3}>
      <Card
            data-aos="fade-right"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "101vh",
              alignItems: "justify",
              py: 5,
            }}
          >
           
           
          </Card>
        </Grid>
        <Grid item key="about" xs={12} md={3} lg={2}>
          <Card
            data-aos="fade-right"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "101vh",
              alignItems: "justify",
              py: 5,
            }}
          >
          {text.pic}
            <CardContent>
            <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "baseline",
                  mb: 2,
                }}
              >
                <Typography
                  component="h4"
                  variant="h4"
                  align="center"
                  color="text.third"
                  gutterBottom
                > 
                  {text.title}
                </Typography>
                <br />
                <Button href="tel:+48690720320" fullWidth color="third" variant={text.buttonVariant}>
                  {text.buttonText}
                </Button>
                <br /><br />
                <Button href="mailto:j.kozubek@kmtlegal.pl" fullWidth color="third"  variant={text.buttonVariant2}>
                  {text.buttonText2}
                </Button>
                <br /><br />
                <Button href="https://www.linkedin.com/in/jakub-kozubek-595337182/?originalSubdomain=pl" fullWidth color="third"  variant={text.buttonVariant2}>
               {text.buttonText3} <LinkedInIcon />
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item key="bio" xs={12} md={9} lg={7}>
          <Card
            data-aos="fade-justify"
            sx={{
              minHeight: "101vh",

              py: 5,
            }}
          >
            <CardContent>
              <Box
                sx={{
                  justifyContent: "justify",
                  maxWidth: "sm",
                  mb: 2,
                  
                }}
              >
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg1}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg2}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg3}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg5}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg6}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg7}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Helmet>
        
        <title>
        Jakub Kozubek 
        </title>
        <meta name="description" content="Kancelaria KMT Jakub Kozubek obsuga prawna przedsiębiorców, podmiotów gospodarczych i indywidualnych klientów"
        />
            <meta name="keywords" content="KMT, Kozubek Matusiak  Radcowie Prawni spółka cywilna, KMT Kozubek Matusiak , KMT Legal, KMT Kozubek Matusiak 
    Jan Matusiak, KMT,  Obsługa prawna przedsiębiorców w Krakowie, Prawnicy w Krakowie, Radcowie prawni w Krakowie, Jakub Kozubek"/>
        <link rel="canonical" href="https://kmtlegal.pl/Jakub%20Kozubek"/>
      </Helmet>
      </Grid>
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}
