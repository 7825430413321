import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import one from "./renders/1.jpeg";
import two from "./renders/1.jpeg";
import three from "./renders/1.jpeg";
import four from "./renders/1.jpeg";
import five from "./renders/1.jpeg";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentCut from "@mui/icons-material/ContentCut";
import NavPc from "./navPc";
import Jakub from "./renders/Jakub.webp";
import Jan from "./renders/Jan.webp";
import Maciej from "./renders/Jan.webp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {Helmet} from "react-helmet";

const text = {
  pic: <CardMedia
  component="img"
  alt="no img"
  width="100%"
  image={Jan}
  sx={{borderRadius:5,
  }}
/>,   title: " Jan Matusiak",
  parg1: "Radca prawny i partner zarządzający KMT.  ",
  parg2: "Specjalizuje się w zagadnieniach cywilnych oraz gospodarczych, ze szczególnym uwzględnieniem problematyki procesowej, prawa spółek oraz spraw upadłościowych.",
  parg3: "Posiada wieloletnie doświadczenie w obsłudze przedsiębiorców, a także osób fizycznych. Przed założeniem KMT doświadczenie zawodowe zdobywał w krakowskich kancelariach prawnych. W ramach swojej szerokiej praktyki procesowej m.in. zajmował się problematyką prawa bankowego, w szczególności reprezentując kredytobiorców w procesach frankowych. Brał także udział w szeregu postępowań o ogłoszenie upadłości i upadłościowych o skomplikowanym charakterze, zarówno po stronie podmiotów niewypłacalnych, jak i wierzycieli. Posiada również doświadczenie w przedmiocie organizacji działalności i prowadzenia podmiotów gospodarczych, ich reorganizacji, przekształceń oraz restrukturyzacji, między innymi dla podmiotów działających w branży medycznej, IT oraz budowlanej. Jednocześnie, w ramach swojej praktyki reprezentował krajowe i zagraniczne podmioty z branży nowych technologii, w tym zajmujący się dostawą technologii blockchain.",
  parg4: "Nakierowany jest na kompleksową obsługę korporacyjną, mającą na celu wsparcie podmiotów gospodarczych na każdym etapie bieżącej działalności oraz ich reprezentowaniu przed właściwymi sądami i organami. Doradza również klientom indywidualnym, świadcząc pomoc w zakresie dotykających ich problemów prawnych.",
  parg5: "Jest autorem wielu analiz i opinii prawnych m.in. w przedmiocie określenia stanu prawnego nieruchomości, w tym na gruncie postanowień międzynarodowego układu indemnizacyjnego (odszkodowawczego), jak również w przedmiocie podziałów podmiotów gospodarczych, ich przekształceń, a także wiążących się z tym konsekwencji podatkowych w związku ze zmianami wynikającymi z tzw. „Nowego Ładu”.",
  parg6: <p> Absolwent Wydziału Prawa i Administracji Uniwersytetu Jagiellońskiego w Krakowie. Członek Okręgowej Izby Radców Prawnych w Krakowie. <br/><br/>Biegle posługuje się prawniczym językiem angielskim, co pozwala mu skutecznie świadczyć usługi prawne dla klientów zagranicznych. </p>,
  parg7:"W wolnym czasie pasjonat literatury historycznej.",
  buttonText: "+48690720320",
  buttonVariant: "outlined",
  buttonText2: "j.matusiak@kmtlegal.pl",
  buttonVariant2: "outlined",
  buttonText3: "LinkedIn",
  buttonVariant3: "outlined",
};



// zmienic na czarny otline przyciskow
function PricingContent() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavPc/>
      <Grid container spacing={0} alignItems="">
      <Grid item key="about" xs={0} md={0} lg={3}>
      <Card
            data-aos="fade-right"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "101vh",
              alignItems: "justify",
              py: 5,
            }}
          >
           
       
          </Card>
        </Grid>
        <Grid item key="about" xs={12} md={3} lg={2}>
          <Card
            data-aos="fade-right"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "101vh",
              alignItems: "justify",
              py: 5,
            }}
          >
               {text.pic}
           
            <CardContent>
            <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "baseline",
                  mb: 2,
                }}
              >
                <Typography
                  component="h4"
                  variant="h4"
                  align="center"
                  color="text.third"
                   
                > 
                  {text.title}
                </Typography>
                <br />
               <Button href="tel:+48690720320" fullWidth color="third" variant={text.buttonVariant}>
                  {text.buttonText}
                </Button>
                <br /><br />
                <Button href="mailto:j.matusiak@kmtlegal.pl" fullWidth color="third"  variant={text.buttonVariant2}>
                  {text.buttonText2}
                </Button>
                <br /><br />
                <Button href="https://www.linkedin.com/in/jan-matusiak-000842183/?originalSubdomain=pl" fullWidth color="third"  variant={text.buttonVariant2}>
               {text.buttonText3} <LinkedInIcon />
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item key="bio" xs={12} md={9} lg={7}>
          <Card
            data-aos="fade-justify"
            sx={{
              minHeight: "101vh",

              py: 5,
            }}
          >
            <CardContent>
              <Box
                sx={{
                  justifyContent: "justify",
                  maxWidth: "sm",
                  mb: 2,
                  
                }}
              >
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                   
                >
                  {text.parg1}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                   
                >
                  {text.parg2}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                   
                >
                  {text.parg3}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                   
                >
                  {text.parg5}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                   
                >
                  {text.parg6}<br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                   
                >
                  {text.parg7}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Helmet>
        
        <title>
        Jan Matusiak 
        </title>
        <meta name="description" content="Kancelaria KMT Jan Matusiak  obsuga prawna przedsiębiorców, podmiotów gospodarczych i indywidualnych klientów"
        />
            <meta name="keywords" content="KMT, Kozubek Matusiak  Radcowie Prawni spółka cywilna, KMT Kozubek Matusiak , KMT Legal, KMT Kozubek Matusiak 
    Jan Matusiak, KMT,  Obsługa prawna przedsiębiorców w Krakowie, Prawnicy w Krakowie, Radcowie prawni w Krakowie, Jan Matusiak"/>
        <link rel="canonical" href="https://kmtlegal.pl/Jan%20Matusiak"/>
      </Helmet>
      </Grid>
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}
