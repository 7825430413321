import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey, teal } from "@mui/material/colors";
import AOS from "aos";
import "aos/dist/aos.css";
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import RestoreIcon from "@mui/icons-material/Restore";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import ReactPlayer from "react-player/lazy";
import { browserName, CustomView } from "react-device-detect";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import logo from "./svg/kmt-z-biały-czerwony.svg";
import logo2 from "./svg/duzykmt-w-biały-czerwony.svg";
import logo3 from "./svg/kmt-h-bialy.svg";
import { Helmet } from "react-helmet";
const theme = createTheme({
  palette: {
    primary: {
      light: "#825b07",
      main: "#825b07",
      dark: "#825b07",
      contrastText: "#000",
    },
    secondary: {
      light: "#022911",
      main: "#022911",
      dark: "#022911",
      contrastText: "#fff",
    },
    third: {
      light: "#000000",
      main: "#000000",
      dark: "#000000",
      contrastText: "#000000",
    },
    fourth: {
      light: "FF0000",
      main: "FF0000",
      dark: "FF0000",
      contrastText: "#000",
    },
  },
  shadows: ["none"],
  typography: {
    fontFamily: ["Lato"].join(","),
    button: {
      textTransform: "none",
    },
  },
});

const names = [
  "Przedsiębiorców",
  "Inwestorów",
  "Start-upów",
  "Programistów",
  "Deweloperów",
];

export default function Album() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const [newName, setnewName] = useState("");
  const [index, setIndex] = useState(0);
  const [value, setValue] = React.useState(0);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const shuffle = useCallback(() => {
    setIndex((prevIndex) => prevIndex + 1);

    setnewName(names[index]);
  }, []);
  if (index > 4) {
    setIndex(0);
  }
  useEffect(() => {
    const interval = setInterval(shuffle, 1500);
    return () => clearInterval(interval);
  }, [shuffle]);

  // const shuffle = useCallback(() => {
  //   const index = Math.floor(Math.random() * names.length);
  //   setnewName(names[index]);
  // }, []);
  // useEffect(() => {
  //   const intervalID = setInterval(shuffle, 500);
  //   return () => clearInterval(intervalID);
  // }, [shuffle]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <main>
        {/* Hero unit */}
        {/* <CustomView condition={browserName !== "Safari"}> */}
        {/* <CustomView condition={browserName !== "Chrome"}>
          <div className="player-wrapper">
            <ReactPlayer
              // ref={videoParentRef}
              playsinline={true}
              className="react-player"
              url="https://youtu.be/iNeIt6UVsTQ"
              width="100vw"
              height="100%"
              volume={0}
              muted={true}
              playsInline={true}
              playing={true}
              loop={true}
              controls={false}
              playbackRate={1}
            />
          </div>
        </CustomView> */}

        <img className="homePic" width="100%" alt="" />
        <Box
          sx={{
            // backgroundColor: "secondary.main",
            // backgroundSize: "cover",
            // opacity: [0.8, 0.8, 0.8],
            height: "101vh",
          }}
        >
            <Box sx={{backgroundColor:"", display: {
            xs:'flex', sm: 'flex', md: 'none' }, justifyContent:'space-between' }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              
              <Button
                    href="/"
                    
                    variant=""
                    sx={{ 
                      align:"glex-end",
                      my: 1,
                      mr:1,
                      color: "secondary.main", display: "block" }}
                  >
                   <img src={logo} width="120" />
                  </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
             
            <MenuItem key={1} onClick={handleCloseNavMenu } href="/ONas">
                <Button
                  sx={{ m: 0.2,  }}
                  style={{justifyContent: "flex-start"}}
                  startIcon={<InfoIcon />}
                  onClick={handleCloseNavMenu}
                  color="secondary"
                  fullWidth
                  href="/About"
                >
                  O Nas
                </Button>
                </MenuItem>
                <MenuItem key={11} onClick={handleCloseNavMenu } href="/ONas">
                <Button
                  sx={{ m: 0.2,  }}
                  style={{justifyContent: "flex-start"}}
                  startIcon={<FormatColorTextIcon />}
                  onClick={handleCloseNavMenu}
                  color="secondary"
                  fullWidth
                  href="/Aktualnosci"
                >
                  Aktualności
                </Button>
                </MenuItem>    
                <MenuItem key={2} onClick={handleCloseNavMenu}>
                <Button
                fullWidth
                  sx={{ m: 0.2 }}
                  style={{justifyContent: "flex-start"}}
                  startIcon={<PeopleAltIcon />}
                  color="secondary"
                  onClick={handleCloseNavMenu}
                  href="/KMT"
                >
                  Zespół
                </Button>
                </MenuItem>  <MenuItem key={3} onClick={handleCloseNavMenu}>
                <Button
                fullWidth
                  sx={{ m: 0.2 }}
                  style={{justifyContent: "flex-start"}}
                  startIcon={<BeenhereIcon />}
                  color="secondary"
                  onClick={handleCloseNavMenu}
                  href="/Specjalizacje"
                >
                  Specjalizacje
                </Button>
                </MenuItem>  
                <MenuItem key={4} onClick={handleCloseNavMenu}>
                <Button
                fullWidth
                  sx={{ m: 0.2 }}
                  style={{justifyContent: "flex-start"}}
                  startIcon={<ContactPhoneIcon />}
                  color="secondary"
                  onClick={handleCloseNavMenu}
                  href="/Kontakt"
                >
                  Kontakt
                </Button>
                </MenuItem>  
                <Button
                    sx={{ m: 0.2, mt: 2 }}
                    
                    size="large"
                    // color="primary"
                    href="/HomeEn"
                    onClick ={ localStorage.setItem('lang', 'eng')}
                    
                  >
                    <Typography
                      data-aos="fade-right"
                      component="h6"
                      variant="h6"
                      align="center"
                      color="secondary"
                      sx={{ fontWeight: 900 }}
                    >
                      En
                    </Typography>
                  </Button>
                <MenuItem key={6} onClick={handleCloseNavMenu}>
                <Button
                fullWidth
                  sx={{ m: 0.2 }}
                  style={{justifyContent: "flex-start"}}
                  startIcon={<LinkedInIcon />}
                  color="secondary"
                  onClick={handleCloseNavMenu}
                  href="https://www.linkedin.com/company/kmt-kozubek-matusiak-truszkiewicz/about/"
                  target="_blank"
                >
                 LinkedIn
                  
                </Button>
              
                  </MenuItem>
        
              </Menu>
            </Box>
          
          {/* <Box
            data-aos="fade-down"
            sx={{
              backgroundColor: "secondary.main",
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Button
                  href="/Jakub Kozubek"
                  sx={{ my: 1, color: "white", display: "flex" }}
                >
                  radca Jakub Kozubek +48 123123123
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  href="/Jan Matusiak"
                  sx={{ my: 1, color: "white", display: "flex" }}
                >
                  radca Jan Matusiak +48 123123123
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  href="/Maciej Truszkiewicz"
                  sx={{ my: 1, color: "white", display: "flex" }}
                >
                  adwokat Maciej Truszkiewicz +48 123123123
                </Button>
              </Grid>
            </Grid>
          </Box>

          <hr /> */}
          <Container maxWidth="l">
            <Grid sx={{ py: 0 }} container spacing={1}>
              <Grid item xs={1}>
                <Button
                  sx={{
                    m: 0.2,
                    pt: 1.5,
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "flex",
                      lg: "flex",
                      xl: "flex",
                    },
                  }}
                  // startIcon={<InfoIcon />}
                  size="large"
                  color="secondary"
                  variant=""
                  href="/ONas"
                >
                  <img src={logo} width="120" alt="" />
                </Button>
              </Grid>

              <Grid items xs={11}>
                <Box
                  data-aos="fade-left"
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "flex",
                      lg: "flex",
                      xl: "flex",
                    },
                  }}
                  justifyContent="flex-end"
                  alignItems="right"
                >
                  <Button
                    sx={{ mx: 0.2, mt: 2 }}
                    // startIcon={<InfoIcon />}
                    size="large"
                    color="secondary"
                    variant=""
                    href="/About"
                  >
                    <Typography
                      data-aos="fade-right"
                      component="h6"
                      variant="h6"
                      align="center"
                      color="white"
                      sx={{ fontWeight: 900 }}
                    >
                      O Nas
                    </Typography>
                  </Button>
                  <Button
                    sx={{ mx: 0.2, mt: 2 }}
                    // startIcon={<InfoIcon />}
                    size="large"
                    color="secondary"
                    variant=""
                    href="/Aktualnosci"
                  >
                    <Typography
                      data-aos="fade-right"
                      component="h6"
                      variant="h6"
                      align="center"
                      color="white"
                      sx={{ fontWeight: 900 }}
                    >
                      Aktualności
                    </Typography>
                  </Button>
                  <Button
                    sx={{ mx: 0.2, mt: 2 }}
                    // startIcon={<InfoIcon />}
                    size="large"
                    color="secondary"
                    variant=""
                    href="/KMT"
                  >
                    <Typography
                      data-aos="fade-right"
                      component="h6"
                      variant="h6"
                      align="center"
                      color="white"
                      sx={{ fontWeight: 900 }}
                    >
                      Zespół
                    </Typography>
                  </Button>
                  <Button
                    sx={{ mx: 0.2, mt: 2 }}
                    // startIcon={<InfoIcon />}
                    size="large"
                    color="secondary"
                    variant=""
                    href="/Specjalizacje"
                  >
                    <Typography
                      data-aos="fade-right"
                      component="h6"
                      variant="h6"
                      align="center"
                      color="white"
                      sx={{ fontWeight: 900 }}
                    >
                      Specjalizacje
                    </Typography>
                  </Button>
                  <Button
                    sx={{ mx: 0.2, mt: 2 }}
                    // startIcon={<InfoIcon />}
                    size="large"
                    color="secondary"
                    variant=""
                    href="/Kontakt"
                  >
                    <Typography
                      data-aos="fade-right"
                      component="h6"
                      variant="h6"
                      align="center"
                      color="white"
                      sx={{ fontWeight: 900 }}
                    >
                      Kontakt
                    </Typography>
                  </Button>

                  <Button
                    sx={{ m: 0.2, mt: 2 }}
                    
                    size="large"
                    // color="primary"
                    href="/HomeEn"
                    onClick ={ localStorage.setItem('lang', 'eng')}
                    
                  >
                    <Typography
                      data-aos="fade-right"
                      component="h6"
                      variant="h6"
                      align="center"
                      color="white"
                      sx={{ fontWeight: 900 }}
                    >
                      En
                    </Typography>
                  </Button>
                  <Button
                    sx={{ m: 0.2, mt: 3 }}
                    size="large"
                    color="secondary"
                    variant=""
                    href="https://www.linkedin.com/company/kmt-kozubek-matusiak-truszkiewicz/about/"
                    target="_blank"
                  >
                    <Typography
                      data-aos="fade-right"
                      component="h6"
                      variant="h6"
                      align="center"
                      color="white"
                      sx={{ fontWeight: 900 }}
                    >
                      <LinkedInIcon />
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
            {/* <Typography
              data-aos="fade-right"
              component="h1"
              variant="h1"
              align="center"
              color="white"
              sx={{
                pt: "1vh",
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              KMT
            </Typography>
            <hr />
            <Typography
              data-aos="fade-right"
              component="h7"
              variant="h7"
              align="center"
              color="white"
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              ADWOKACI I RADCOWIE PRAWNI
            </Typography>
            <Typography
              data-aos="fade-right"
              component="h6"
              variant="h6"
              align="center"
              color="white"
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              <br />
              Kozubek
              <br />
              Matusiak
              <br />
              Truszkiewicz
            </Typography> */}
            <Container
              align="left"
              maxWidth="sm"
              sx={{
                pt: "1vh",
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              <br />
              <br />
              <img src={logo2} width="70%" alt="" />
              <br />
              <br />
            </Container>
          </Container>
          <Container
            direction="column"
            spacing={2}
            justifyContent="center"
            sx={{
              pt: "15vh",
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
                xl: "block",
              },
            }}
          >
            <img src={logo3} width="80%" alt="" />
          </Container>
          <Container
            data-aos="fade-right"
            maxWidth="md"
            sx={{
              alignItems: "center",
              // ml: "-2vw",
            }}
          >
            <Box
              sx={{
                // backgroundColor: "white",
                // backgroundSize: "cover",
                // opacity: [0.9, 0.9, 0.8],
                // minHeight: "95vh",
                alignItems: "center",
                py: 5,
                mb: 12,
              }}
            >
              <Container
                data-aos="zoom-in"
                data-aos-anchor-placement="top-bottom"
                maxWidth="md"
                sx={{
                  opacity: [0.1, 0.99, 1],
                }}
              >
                <Stack
                  // sx={{ pt: 5 }}
                  direction="column"
                  spacing={2}
                  justifyContent="center"
                  sx={{
                    py: 6,
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                      lg: "block",
                      xl: "block",
                    },
                  }}
                >
                  <Typography
                    component="h4"
                    variant="h4"
                    align="center"
                    color="white"
                    gutterBottom
                  >
                    Kancelaria dla
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    align="center"
                    color="white"
                    // sx={{ pb: 12 }}
                  >
                    {names[index]}
                  </Typography>
                </Stack>{" "}
                <Container
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "flex",
                      lg: "flex",
                      xl: "flex",
                    },
                  }}
                >
                  <Typography
                    component="h1"
                    variant="h5"
                    align="justify"
                    color="white"
                    className="homeText"
                  >
                    Jesteśmy kancelarią prawną powstałą z myślą o kompleksowym
                    wsparciu biznesu przy wykorzystaniu doświadczonego zespołu i
                    nowych technologii. Wraz z Tobą tworzymy{" "}
                    <b>jeden zespół </b>
                    dla realizacji Twoich celów.
                  </Typography>
                </Container>
                <Stack
                  // sx={{ pt: 5 }}
                  direction="column"
                  spacing={2}
                  justifyContent="center"
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "none",
                      lg: "none",
                      xl: "none",
                    },
                  }}
                >
                  <Button
                    size="large"
                    href="https://goo.gl/maps/PiQzy4NwX1Y7FHC9A"
                    color="fourth"
                    variant="contained"
                    fullWidth
                  >
                    <Box
                      sx={{
                        backgroundColor: "red",
                        width: "80vw",
                      }}
                    >
                      <Typography
                        component="h6"
                        variant="h6"
                        align="center"
                        color="white"
                        gutterBottom
                      >
                        Nawiguj
                      </Typography>
                    </Box>
                  </Button>
                  <br />
                  <Button
                    size="large"
                    color="fourth"
                    variant="contained"
                    href="tel:+48690720320"
                    fullWidth
                  >
                    <Box
                      sx={{
                        backgroundColor: "red",
                        width: "80vw",
                      }}
                    >
                      <Typography
                        component="h6"
                        variant="h6"
                        align="center"
                        color="white"
                        gutterBottom
                      >
                        Zadzwoń
                      </Typography>
                    </Box>
                  </Button>
                  <br />
                  <Button
                    size="large"
                    color="fourth"
                    variant="contained"
                    href="mailto:biuro@kmtlegal.pl"
                    fullWidth
                  >
                    <Box
                      sx={{
                        backgroundColor: "red",
                        width: "80vw",
                      }}
                    >
                      <Typography
                        component="h6"
                        variant="h6"
                        align="center"
                        color="white"
                        gutterBottom
                      >
                        Wyślij e-mail
                      </Typography>
                    </Box>
                  </Button>
                </Stack>{" "}
              </Container>
            </Box>
          </Container>
          <Helmet>
            <title>
              KMT Legal Kozubek Matusiak  Radcowie Prawni
            </title>
            <meta
              name="description"
              content="Kancelaria KMT Kozubek Matusiak  obsuga prawna przedsiębiorców, podmiotów gospodarczych i indywidualnych klientów"
            />
            <meta
              name="keywords"
              content="KMT, Kozubek Matusiak  Radcowie Prawni spółka cywilna, KMT Kozubek Matusiak , KMT Legal, KMT Kozubek Matusiak 
    Jan Matusiak, KMT,  Obsługa prawna przedsiębiorców w Krakowie, Prawnicy w Krakowie, Radcowie prawni w Krakowie"
            />
            <link rel="canonical" href="https://kmtlegal.pl" />
          </Helmet>
        </Box>
      </main>
    </ThemeProvider>
  );
}
